<script>
import Layout from "@/router/layouts/main";
import { api } from "@/state/services";

export default {
  locales: {
    pt: {},
    es: {},
  },
  components: {
    Layout,
  },
  data() {
    return {
      contract: {
        id: null,
        status: null,
        signed: null,
        date_added: null,
        date_signed: null,
        type: null,
        value: null,
        time: null,
      },
    };
  },
  methods: {
    getContract() {
      api.get("investments/contracts/" + this.$route.params.id).then((response) => {
        if (response.data.status == "success") {
          this.contract = response.data.contract;
        }
      });
    },
  },
  mounted() {
    this.getContract();
  },
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex justify-content-between">
          <h4 class="mb-0 font-size-22">{{ contract.type }}</h4>
        </div>
      </div>
    </div>
    <template v-if="contract.id">
      <div v-if="contract.status && contract.status == 'pending'" class="card">
        <div class="card-body p-4">
          <h5 class="mb-4">Parabéns!</h5>
          <p>Sua reserva de investimento no valor de <strong>{{ contract.value | currency }}</strong> na operação <strong>{{ contract.type }}</strong> foi realizada com sucesso!</p>

          <p>A partir de agora, você tem duas opções:</p>
          <p>1) Garantir a sua participação realizando o pagamento do boleto em até 03 dias corridos ou;</p>
          <p>2) Realizar o pagamento apenas quando a operação atingir o alvo mínimo. Lembrando que, em ambas as situações, você poderá desistir da sua participação em até 05 dias após o pagamento.</p>

          <div v-if="contract.pix && contract.pix.qrcode && contract.pix.copypaste" class="row justify-content-center mb-4 text-center">
            <div class="col-md-12">
              <div class="bg-soft-light rounded p-4">
                <img style="width: 250px;" :src="contract.pix.qrcode"/>
                <p>Leia o qrcode acima ou</p>
                <ol class="text-left">
                  <li>Abra o app do seu banco ou seu app de pagamentos.</li>
                  <li>Busque a opção de pagar com pix.</li>
                  <li>Copie e cole o seguinte código.</li>
                  <li>Após o pagamento envie o comprovante da tranferência.</li>
                </ol>

                <div class="border rounded p-3 mb-3 text-left">
                  {{ contract.pix.copypaste }}
                </div>

                <button class="btn btn-default" v-clipboard:copy="contract.pix.copypaste"
            v-on:click="$toast.success('Copiado!')"><i class="bx bx-copy font-size-18 align-middle"></i> Copiar</button>
              </div>
            </div>
          </div>
          <p>Em função de regulação da Comissão de Valores Mobiliários (CVM) e de forma a dar mais segurança às nossas operações, cada captação possui sua própria Conta Escrow. Estas contas são movimentadas apenas pela própria Instituição Financeira (qitech.com.br), reguladas e fiscalizadas pelo Banco Central.</p>
        </div>
      </div>
      <div v-else-if="contract.status && contract.status == 'approved'">
      </div>
    </template>
    <div v-else class="text-center">
      <b-spinner
        small
        class="ml-2 align-middle"
        variant="default"
        role="status"
      ></b-spinner>
    </div>
  </Layout>
</template>
